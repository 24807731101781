import { Injectable, signal, WritableSignal, inject } from '@angular/core';
import { BaseRequiredProfile, BaseSchedule, Mission, MissionExercise, Practitioner, ProfessionsWithSpeciality, Structure } from '@appines/appines_types';
import { Subject } from 'rxjs';
import { StateStoreService } from 'src/services/state-store/state-store.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import dayjs from 'dayjs';
import { FormatService } from 'src/services/format/format.service';

export type CraftedMission = Partial<Mission> & {
  schedule: Omit<BaseSchedule, 'startDate' | 'endDate'> & {
    startDate: Date | string;
    endDate?: Date | string;
  };
  requiredProfile?: { professionWithSpecialityId?: string; skillsId?: string[] };
  practitionerToReplaceId?: string;
  practitionerProfessions?: ProfessionsWithSpeciality[];
  usersToNotify?: string[];
};

export type SubstitutionRemuneration = {
  averageActCost: number;
  maxActPerWeek: number;
  minActPerWeek: number;
  holderPercentage: number;
  description: string;
};

export type CessionRemuneration = {
  averageActCost?: number;
  maxActPerWeek?: number;
  minActPerWeek?: number;
};

export type FormMissionExercise = Exclude<MissionExercise, 'volunteering'>;

export type Estimation = { startDate: string; endDate?: string; holderPercentage: number; minActPerWeek: number; maxActPerWeek: number; averageActCost: number };

type FormattedMission = Omit<CraftedMission, 'requiredProfile'> & { requiredProfile?: Omit<BaseRequiredProfile, 'skills' | 'profession'> & { professionWithSpecialityId?: string; skillsId?: string[] }; structureId: string };

@Injectable({
  providedIn: 'root'
})
export class MissionFormService extends StateStoreService<CraftedMission> {
  private http = inject(HttpClient);
  private formatService = inject(FormatService);

  formSubject = new Subject<{ direction: 'next' | 'back'; params?: any }>();
  saveDataSubject = new Subject<null>();
  nextButtonEnable = signal<boolean>(false);
  estimation: WritableSignal<Estimation> = signal({
    startDate: dayjs().toISOString(),
    endDate: undefined,
    holderPercentage: 0,
    minActPerWeek: 0,
    maxActPerWeek: 0,
    averageActCost: 0
  });
  isLoading = signal<boolean>(false);
  practitioners = signal<Practitioner[]>([]);
  structures = signal<Structure[]>([]);
  professions = signal<ProfessionsWithSpeciality[]>([]);

  emitData(data: { direction: 'next' | 'back'; params?: any }) {
    this.formSubject.next(data);
  }

  initForm(exercise: FormMissionExercise) {
    const mission: CraftedMission = {
      exercise
    } as CraftedMission;

    if (this.structures().length === 1) {
      mission.structure = this.structures()[0];
    }
    this.setState(mission);
  }

  saveData() {
    this.saveDataSubject.next(null);
  }

  createMission(draft?: boolean) {
    const url = environment.apiUrl + '/missions';
    return this.http.post(url, this.formatMissionBeforeCreate(draft));
  }

  private formatMissionBeforeCreate(draft?: boolean) {
    let newMission: FormattedMission = {
      status: draft ? 'DRAFT' : undefined,
      exercise: this.state().exercise,
      practitionerToReplaceId: this.state().practitionerToReplaceId,
      usersToNotify: this.state().usersToNotify,
      title: this.state().title,
      description: this.state().description,
      structureId: this.state()?.structure?._id as string,
      schedule: this.state().schedule,
      remuneration: this.state().remuneration,
      characteristics: this.state().characteristics
    };

    if (this.state().exercise === 'cession') {
      newMission = this.cleanCession(newMission);
    }

    if (this.state().exercise !== 'cession' || (this.state().exercise === 'cession' && this.state().characteristics?.patientIncluded)) {
      newMission.requiredProfile = {
        professionWithSpecialityId: this.state().requiredProfile?.profession!._id.toString(),
        skillsId:
          this.state()
            ?.requiredProfile?.skills?.map((skill) => skill._id?.toString())
            .filter((id): id is string => id !== undefined) || undefined,
        acceptedYears: this.state().requiredProfile?.acceptedYears,
        requiredExperience: this.state().requiredProfile?.requiredExperience
      };
    }

    return this.formatService.cleanObject(newMission);
  }

  resetEstimation() {
    const redevance = this.state().remuneration as SubstitutionRemuneration;
    this.estimation.set({
      startDate: this.state().schedule?.startDate as string,
      endDate: this.state().schedule?.endDate as string,
      holderPercentage: redevance!.holderPercentage,
      minActPerWeek: redevance!.minActPerWeek,
      maxActPerWeek: redevance!.maxActPerWeek,
      averageActCost: redevance!.averageActCost
    });
  }

  cleanCession(mission: FormattedMission): FormattedMission {
    if (!mission.characteristics?.structureIncluded) {
      delete mission.characteristics?.turnover;
      delete mission.characteristics?.activityVolume;
      delete mission.characteristics?.structureParts;
      delete mission.characteristics?.structureCreationDate;
      delete mission.characteristics?.colleaguesAmount;
      delete mission.characteristics?.colleaguesMaxCapacity;
      delete mission.characteristics?.withWalls;
      delete mission.characteristics?.withAgreement;
      delete mission.characteristics?.charges;
      delete mission.characteristics?.rent;
    }

    if (!mission.characteristics?.patientIncluded) {
      delete mission.characteristics?.patientParts;
      delete mission.remuneration?.minActPerWeek;
      delete mission.remuneration?.maxActPerWeek;
      delete mission.remuneration?.averageActCost;
      delete mission.requiredProfile;
    }

    if (!mission.characteristics?.equipmentIncluded) {
      delete mission.characteristics?.equipmentDescription;
    }
    return mission;
  }
}
